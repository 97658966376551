<template>
  <div class="forget-password-wrap">
    <LogoHeader />
    <section class="reset-form-area">
      <el-form
        label-position="top"
        class="reset-form"
        :model="resetForm"
        :rules="rules"
        :validate-on-rule-change="false"
        ref="resetForm"
      >
        <div class="slogan">
          <p class="big-slogan">{{ $t('reset.slogan') }}</p>
          <p class="small-slogan">{{ $t('reset.slogan1') }}</p>
        </div>
        <el-form-item
          :label="$t('reset.emall')"
          prop="email"
        >
          <el-input v-model="resetForm.email" size="medium" :placeholder="$t('reset.emall.placeholder')" @blur="checkEmail" />
        </el-form-item>
        <el-form-item
          :label="$t('reset.code')"
          prop="code"
        >
          <div class="code-area">
            <el-input v-model="resetForm.code" size="medium" :placeholder="$t('reset.code.placeholder')" />
            <el-button
              :disabled="codeLoading.isLoading || canGetCode"
              :loading="getCodeLoading"
              class="get-code-btn"
              type="primary"
              @click.stop="getCode"
            >
              {{ codeLoading.isLoading ? `${codeLoading.times}s` : $t('reset.acquire') }} 
            </el-button>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('reset.password')"
          prop="password"
        > 
          <div style="height: 0; width: 0; overflow: hidden;">
            <el-input></el-input>
            <el-input type="password"></el-input>
          </div>
          <el-input v-model="resetForm.password" size="medium" type="password" show-password :placeholder="$t('reset.password.placeholder')" />
        </el-form-item>
        <el-form-item
          :label="$t('reset.confimpassword')"
          prop="password_confirmation"
        >
          <el-input v-model="resetForm.password_confirmation" size="medium" type="password" show-password :placeholder="$t('reset.confimpassword.placeholder')" />
        </el-form-item>
      </el-form>
      <div class="actions">
        <el-button :loading="resetBtnLoading" class="reset-action" type="primary" @click.stop="resePassword">
          {{ $t('reset.reset') }}
        </el-button>
        <p class="login-tip">
          <span>{{ $t('reset.backlogin') }}</span>
          <el-button type="text" @click.stop="toLogin">
            {{ $t('reset.backlogin1') }}
          </el-button>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import LogoHeader from './LogoHeader.vue';
import { dataInterface } from '@/apis/data'
const emailReg = /^[^.\s]+(\.[^.\s]+)*@\S+(\.[^.\s]+)+$/i;
export default {
  components: {
    LogoHeader
  },
  data() {
    return {
      resetForm: {
        email: '',
        code: '',
        password: '',
        password_confirmation: '',
      },
      // 获取验证码loading
      getCodeLoading: false,
      // 验证码 禁用
      codeLoading: {
        isLoading: false,
        times: 0
      },
      resetBtnLoading: false,
    }
  },
  computed: {
    /**
     * @description: 校验规则
     * @return {*}
     */    
    rules() {
      return {
        email: [
          { required: true, message: this.$t('reset.emptymsg'), trigger: 'change' },
          { validator: (rule, value, cb) => {
              // TODO 电话号码验证未确认
              if(emailReg.test(value)) {
                cb();
              } else {
                cb(new Error(this.$t('register.account.formatError')))
              }
            }, trigger: 'change'}
        ],
        code: [
          { required: true, message: this.$t('reset.emptymsg'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('reset.emptymsg'), trigger: 'change' },
          { validator: (rule, value, cb) => {
              // eslint-disable-next-line no-useless-escape
              const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[()`!@#$%^&*_+=|{}[\]:;'<>,\.?-])[0-9a-zA-Z()`!@#$%^&*_+=|{}[\]:;'<>,\.?-]{8,16}/;
              if(reg.test(value)) {
                cb();
              } else {
                cb(new Error(this.$t('reset.password.error')));
              }
            }, trigger: 'change' },
        ],
        password_confirmation: [
          { required: true, message: this.$t('reset.emptymsg'), trigger: 'change' },
          { validator: (rule, value, cb) => {
              if(this.resetForm.password === value) {
                cb();
              } else {
                cb(new Error(this.$t('reset.confimpassword.error')));
              }
            }, trigger: 'change'}
        ]
      }
    },
    /**
     * @description: 是否能请求验证码
     * @return {*}
     */    
     canGetCode() {
      const email = this.resetForm.email;
      return !emailReg.test(email);
    }
  },
  methods: {
    toLogin() {
      this.$emit('changeMode', 'LoginForm');
    },
    /**
     * @description: 获取验证码
     * @return {*}
     */    
     getCode() {
      const REQDATA = {
        email: this.resetForm.email
      }
      this.getCodeLoading = true;
      dataInterface(REQDATA, 'api/v4/user/reset/sendEmailCode').then((res) => {
        if(res.data.code === 200) {
          const codeTimeStamp = new Date().getTime();
          sessionStorage.setItem(`restcodeTimeStamp-${this.resetForm.email}`, codeTimeStamp.toString());
          this.startCodeLoading();
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.getCodeLoading = false;
      })
    },
    /**
     * @description: 验证邮箱切换loading
     * @return {*}
     */    
    checkEmail() {
      this.closeCodeLoading();
      this.startCodeLoading();
    },
    /**
     * @description: 开始验证码loading
     * @return {*}
     */    
    startCodeLoading() {
      let timeStamp = sessionStorage.getItem(`restcodeTimeStamp-${this.resetForm.email}`);
      if(timeStamp) {
        timeStamp = Number(timeStamp) - Number(timeStamp) % 1000;
        let limit = timeStamp - new Date().getTime() + 300000;
        limit = Math.floor(limit / 1000);
        if(limit > 0) {
          this.codeLoading.isLoading = true;
          this.codeLoading.times = limit;
          this.codeIntervalFlag = setInterval(() => {
            if(limit <= 0) {
              this.closeCodeLoading();
              return;
            }
            this.codeLoading.times = limit;
            limit--;
          }, 1000)
        }
      }
    },
    /**
     * @description: 结束验证码loading
     * @return {*}
     */    
    closeCodeLoading() {
      this.codeLoading.isLoading = false;
      this.codeLoading.times = 0;
      clearInterval(this.codeIntervalFlag)
    },
    /**
     * @description: 重置密码
     * @return {*}
     */    
    resePassword() {
      this.$refs.resetForm.validate((valid) => {
        if(valid) {
          const REQDATA = {
            ...this.resetForm
          };
          dataInterface(REQDATA, 'api/v4/user/reset/pwd').then((res) => {
            if(res.data.code === 200) {
              this.toLogin();
            }
          }).catch(err => {
            console.error(err)
          }).finally(() => {
            this.resetBtnLoading = false;
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .forget-password-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .reset-form-area{
      margin-top: 60px;
      flex-grow: 1;
      width: 100%;
      .reset-form{
        display: flex;
        flex-direction: column;
        gap: 28px;
        .slogan{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--spacing-6, 12px);
          align-self: stretch;
          .big-slogan{
            color: var(--text-on-surface-primary, #181B22);
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.1px;
          }
          .small-slogan{
            color: var(--text-on-surface-secondary, #424751);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 183.333% */
            letter-spacing: 0.036px;
          }

        }
        :deep(.el-form-item) {
          margin: 0;
          .el-form-item__label{

            &::before{
              display: none;
            }
            height: 32px;
            padding: 0;
            line-height: 32px;
            color: var(--text-on-surface-secondary, #424751);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            // line-height: 22px; /* 183.333% */
            letter-spacing: 0.036px;
          }
          &.is-required{
            .el-form-item__label{
              display: flex;
              align-items: center;
              gap: 4px;
              &::after{
                content: '*';
                display: block;
                height: 22px;
                color: var(--text-on-surface-required, #ED474A);
                /* web/cn/heading/heading-01 */
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
                letter-spacing: 0.5px;
              }
            }
          }
          .el-form-item__error{
            padding: 0;
            margin: 0;
            position: absolute;
            color: var(--text-on-surface-required, #ED474A);
            /* web/cn/helper/helper-02 */
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
            letter-spacing: 0.4px;
          }
        }
        :deep(.el-input) {
          height: 48px;
          border-radius: var(--radius-3, 6px);
          .el-input__inner{
            height: 100%;
            padding: 0 12px 0 16px;
            background: var(--field-background-default-variant, #F2F5FA);
            border: 1px solid transparent;
            &:hover{
              border: 1px solid var(--themeColor);
              background: var(--field-background-default, #FFF);
            }
            &:focus{
              background: var(--field-background-default, #FFF);
              border: 1px solid var(--themeColor);
              /* field-border/on-surface/focus */
              box-shadow: 0px 0px 0px 1px #4F8EFF inset, 0px 0px 0px 2px #D1E3FF;
            }
          }
          .el-icon-view{
            color: #8991A2;
          }
        }
        :deep(.el-select) {
          width: 100%;
        }
        .code-area{
          width: 100%;
          display: flex;
          align-items: center;
          gap: var(--spacing-6, 12px);
          .get-code-btn{
            height: 48px;
            padding: 0px var(--spacing-8, 16px);
            border: none;
            border-radius: var(--radius-3, 6px);
            background: var(--_button-brand-tertiary-background, #E7F0FE);
            color: var(--themeColor);
            /* web/cn/body/body-02 */
            font-family: "PingFang SC";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.5px;

            &:hover{
              border-radius: var(--radius-3, 6px);
              background: var(--_button-brand-tertiary-background-hover, #D1E3FF);
            }

            &:active{
              border-radius: var(--radius-3, 6px);
              background: var(--_button-brand-tertiary-background-active, #BED7FF);
            }
          }
        }
      }
      .actions{
        width: 100%;
        display: flex;
        padding: 40px var(--spacing-none, 0px);

        flex-direction: column;
        gap: 32px;
        .reset-action{
          height: var(--button-list-field-large, 40px);
          padding: 0px var(--spacing-8, 16px);

          border-radius: var(--radius-3, 6px);
          background: var(--themeColor);
          color: var(--_button-brand-primary-on-background, #FFF);
          /* web/cn/body/body-02 */
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: 0.5px;
          opacity:0.8;

          &:hover{
            border-radius: var(--radius-3, 6px);
            background: var(--themeColor);
            opacity:1;
          }

          &:active{
            border-radius: var(--radius-3, 6px);
            background: var(--el-color-primary);
          }

        }
        .login-tip{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--spacing-4, 8px);

          align-self: stretch;
          color: var(--text-on-surface-primary, #181B22);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          letter-spacing: 0.036px;
          :deep(.el-button--text) {
            padding: 2px 0;
            color: var(--themeColor)!important;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: 0.036px;
          }
        }
      }
    }
  }
</style>