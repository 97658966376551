<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-03-21 09:39:18
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-06-13 11:38:58
 * @FilePath: \dataview-viewer-test\src\views\login\jida\components\LogoHeader.vue
 * @Description: logo 即 国际化切换
-->
<template>
  <section class="logo-header-wrap">
    <div class="logo-images">
      <img v-for="(item, index) in logoimages" :key="index" class="logo-item" :src="item.src" alt="logo">
    </div>
    <div class="language-switch" @click.stop="changeLanguage">
      <transition name="fade" mode="out-in">
        <svg v-if="localLanguage === 'en'" xmlns="http://www.w3.org/2000/svg" width="26" key="1" height="26" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M19.829 16.6622H23.4736C24.407 16.6622 24.8736 17.1288 24.8736 18.0622V23.5782C24.8736 24.5115 24.407 24.9782 23.4736 24.9782H19.829C18.8956 24.9782 18.429 24.5115 18.429 23.5782V18.0622C18.429 17.1288 18.8956 16.6622 19.829 16.6622Z" fill="#8991A2"/>
          <path d="M17.8061 13.7741H18.5461V15.5741H22.1361V20.2741H21.4161V19.6441H18.5461V23.0541H17.8061V19.6441H14.9461V20.2741H14.2261V15.5741H17.8061V13.7741ZM14.9461 18.9441H17.8061V16.2741H14.9461V18.9441ZM18.5461 18.9441H21.4161V16.2741H18.5461V18.9441Z" fill="#8991A2"/>
          <rect x="11.1169" y="11.1175" width="13.75" height="13.75" rx="1" stroke="#8991A2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.633179 2.1325C0.633179 1.30407 1.30475 0.6325 2.13318 0.6325H13.8832C14.7116 0.6325 15.3832 1.30407 15.3832 2.1325V13.8825C15.3832 14.7109 14.7116 15.3825 13.8832 15.3825H2.13318C1.30475 15.3825 0.633179 14.7109 0.633179 13.8825V2.1325ZM13.0453 4.86639V11.2648H12.1927L8.97156 6.32977V11.2648H8.12341V4.86639H8.97156L12.2059 9.81463V4.86639H13.0453ZM7.07312 11.2648V10.5749H3.85632V8.3073H6.62488V7.61736H3.85632V5.56072H7.02917V4.86639H3.85632H3.68494H3.00818V11.2648H3.68494H3.85632H7.07312Z" fill="#8991A2"/>
        </svg>
  
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="26" height="26" key="2" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M9.83897 6.67194H13.4836C14.417 6.67194 14.8836 7.1386 14.8836 8.07194V13.5879C14.8836 14.5213 14.417 14.9879 13.4836 14.9879H9.83897C8.90563 14.9879 8.43896 14.5213 8.43896 13.5879V8.07194C8.43896 7.1386 8.90563 6.67194 9.83897 6.67194Z" fill="#8991A2"/>
          <path d="M13.0205 4.80304V11.2015H12.168L8.94678 6.26642V11.2015H8.09863V4.80304H8.94678L12.1812 9.75128V4.80304H13.0205Z" fill="#8991A2"/>
          <path d="M7.04834 10.5115V11.2015H3.66016V10.5115H7.04834ZM3.83154 4.80304V11.2015H2.9834V4.80304H3.83154ZM6.6001 7.55402V8.24396H3.66016V7.55402H6.6001ZM7.00439 4.80304V5.49738H3.66016V4.80304H7.00439Z" fill="#8991A2"/>
          <rect x="1.12695" y="1.12726" width="13.75" height="13.75" rx="1" stroke="#8991A2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.123 10.6227C11.2946 10.6227 10.623 11.2943 10.623 12.1227V23.8727C10.623 24.7012 11.2946 25.3727 12.123 25.3727H23.873C24.7015 25.3727 25.373 24.7012 25.373 23.8727V12.1227C25.373 11.2943 24.7015 10.6227 23.873 10.6227H12.123ZM18.32 14.0001H17.58V15.8001H14V20.5001H14.72V19.8701H17.58V23.2801H18.32V19.8701H21.19V20.5001H21.91V15.8001H18.32V14.0001ZM17.58 19.1701H14.72V16.5001H17.58V19.1701ZM21.19 19.1701H18.32V16.5001H21.19V19.1701Z" fill="#8991A2"/>
        </svg>
      </transition>
    </div> 
  </section>
</template>
<script>

export default {
  data() {
    return {
      // WHY 留着后续logo可能带链接点击
      logoimages: [
        {
          src: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/99903f43d92fadee85936dbdf06d7490.png',
        },
        // {
        //   src: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/11150f450d77667c314647a99a4b4896.png',
        // }
      ],
      localLanguage: 'zh'
    }
  },
  created() {
    // 获取初始化的 国际化语言
    this.localLanguage = this.$i18n.locale || localStorage.getItem('preferred_lang') || 'zh';
  },
  methods: {
    /**
     * @description: 改变语言
     * @return {*}
     */    
    changeLanguage() {
      let lang = 'zh'
      if(this.localLanguage === 'en') {
        lang = 'zh'
      } else if (this.localLanguage === 'zh') {
        lang = 'en'
      }
      this.localLanguage = lang;
      localStorage.setItem('preferred_lang', lang);
      this.$i18n.locale = lang;
    }
  }
}
</script>
<style lang="less" scoped>
  .logo-header-wrap{
    height: 60px;
    width: 100%;

    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    .logo-images{
      height: 100%;
      display: flex;
      align-items: center;
      gap: 14px;
      .logo-item{
        height: 100%;
        width: auto;
        object-fit: fill;
      }
    }
    .language-switch{
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .fade-enter-active {
    transition: all .3s ease;
    opacity: 1;
  }
  .fade-leave-active {
    transition: all .2s linear;
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
    opacity: 0.5;
  }
</style>