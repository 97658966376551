<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-03-21 09:38:00
 * @LastEditors: zqy
 * @LastEditTime: 2024-07-08 09:50:17
 * @FilePath: /dataview-viewer-test/src/views/login/jida/components/LoginForm.vue
 * @Description: 登录表单
-->
<template>
  <div class="login-form-wrap">
    <LogoHeader />
    <section class="login-form-area">
      <el-form
        label-position="top"
        class="login-form"
        :model="loginForm"
        :rules="rules"
        :validate-on-rule-change="false"
        ref="loginForm"
      >
        <div class="slogan">
          <p>{{ $t('login.slogan') }}</p>
          <p>{{ $t('login.slogan1') }}</p>
        </div>
        <el-form-item
          :label="$t('login.account')"
          prop="name"
        >
          <el-input v-model="loginForm.name" size="medium" :placeholder="$t('login.account.placeholder')" />
        </el-form-item>
        <el-form-item
          prop="password"
          :label="$t('login.password')"
        >
          <el-input v-model="loginForm.password" size="medium" type="password" show-password :placeholder="$t('login.password.placeholder')" />
        </el-form-item>
        <p class="forgot-password">
          <el-button type="text" @click.stop="toResetPassword">
            {{ $t('login.forgotPassword') }}
          </el-button>
        </p>
      </el-form>
      <div class="actions">
        <el-button class="login-action" type="primary" @click.stop="onLogin">
          {{ $t('login.logintext') }}
        </el-button>
        <!-- 用户隐私 -->
        <div class="privacy-wrap">
          <svg
            v-if="isChecked"
            aria-hidden="true"
            class="svg"
            @click="onChecked"
          >
            <use xlink:href="#iconyonghuxieyiyigouxuan-copy" />
          </svg>
          <svg
            v-else
            aria-hidden="true"
            class="svg"
            @click="onChecked"
          >
            <use xlink:href="#iconyonghuxieyidaigouxuan-copy" />
          </svg>
          <p class="privacy" v-if="!isEn">知晓并同意系统<span @click="onShowPrivacy">《用户隐私声明》</span></p>
          <p class="privacy" v-else @click="onShowPrivacy">Understand and agree to the <span>system's User Privacy Statement</span>.</p>
        </div>
        <!-- TIPS 注册暂时隐藏 -->
        <p v-show="false" class="register-tip">
          <span>{{ $t('login.registertip') }}</span>
          <el-button type="text" @click.stop="toRegister">
            {{ $t('login.registertip1') }}
          </el-button>
        </p>
      </div>
    </section>
    <!-- 用户协议被取消 -->
    <!-- <div class="login-footer">
      <span>{{ $t('login.agreementTip') }}</span>
      <el-button type="text">
        {{ $t('login.privacyAgreement') }}
      </el-button>
      <span>{{ $t('login.agreementTip1') }}</span>
      <el-button type="text" >
        {{ $t('login.userAgreement') }}
      </el-button>
    </div> -->
  </div>
</template>
<script>
import LogoHeader from './LogoHeader.vue';
import mixin from '@/custom-component/login/mixin';
import i18n from '@/locale/index';

export default {
  mixins: [mixin],
  components: {
    LogoHeader,
  },
  data() {
    return {
      loginForm: {
        name: '',
        password: ''
      },
      isChecked: false,
    }
  },
  computed: {
    rules() {
      return {
        name: [
          { required: true, message: this.$t('login.account.errmsg'), trigger: 'blur' },
        ],
        password: [
          { required: true, message: this.$t('login.password.errmsg'), trigger: 'blur' },
        ],
      }
    },
    isEn() {
      if (i18n.locale === 'en') {
        return true;
      }
      return false;
    }
  },
  methods: {
    onShowPrivacy() {
      this.$emit('onShowPrivacy');
    },
    onChecked() {
      this.isChecked = !this.isChecked;
    },
    /**
     * @desc: 登录
     */
    onLogin() {
      if (!this.isChecked) {
        this.$message.error(this.$t('login.privacyInfo'));
        return;
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$loading();
          try {
            this.doLogin({
              ...this.loginForm
            })
          } catch (err) {
            this.$loading().close();
            this.$message.error(this.$t('login.errortip'));
          }
        }
      });
    },
    /**
     * @description: 去重置密码
     * @return {*}
     */    
    toResetPassword() {
      this.$emit('changeMode', 'ForgetPassword');
    },
    /**
     * @description: 去注册
     * @return {*}
     */    
    toRegister() {
      this.$emit('changeMode', 'RegisterForm');
    }
  }
}
</script>
<style lang="less" scoped>
  .login-form-wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    .login-form-area{
      flex-grow: 1;
      width: 100%;
      .login-form{
        display: flex;
        flex-direction: column;
        gap: 24px;
        .slogan{
          font-family: "PingFang SC";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 36px */
          letter-spacing: 0.5px;

          margin-bottom: 12px;
        }
        :deep(.el-form-item) {
          margin: 0;
          .el-form-item__label{

            &::before{
              display: none;
            }
            height: 32px;
            padding: 0;
            line-height: 32px;
            color: var(--text-on-surface-secondary, #424751);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            // line-height: 22px; /* 183.333% */
            letter-spacing: 0.036px;
          }
          .el-form-item__error{
            padding: 0;
            margin: 0;
            position: absolute;
            color: var(--text-on-surface-required, #ED474A);
            /* web/cn/helper/helper-02 */
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
            letter-spacing: 0.4px;
          }
        }
        :deep(.el-input) {
          height: 48px;
          border-radius: var(--radius-3, 6px);
          .el-input__inner{
            height: 100%;
            padding: 0 12px 0 16px;
            background: var(--field-background-default-variant, #F2F5FA);
            border: 1px solid transparent;
            &:hover{
              border: 1px solid var(--themeColor);
              background: var(--field-background-default, #FFF);
            }
            &:focus{
              background: var(--field-background-default, #FFF);
              border: 1px solid var(--themeColor);
              /* field-border/on-surface/focus */
              box-shadow: 0px 0px 0px 1px #4F8EFF inset, 0px 0px 0px 2px #D1E3FF;
            }
          }
          .el-icon-view{
            color: #8991A2;
          }
        }
        .forgot-password{
          display: flex;
          justify-content: flex-end;
          height: 24px;
          :deep(.el-button--text) {
            padding: 4px 0;
            color: var(--themeColor);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: 0.036px;
          }
        }
      }
      .actions{
        width: 100%;
        display: flex;
        padding: 32px var(--spacing-none, 0px);

        flex-direction: column;
        // gap: 32px;
        .login-action{
          height: var(--button-list-field-large, 40px);
          padding: 0px var(--spacing-8, 16px);

          border-radius: var(--radius-3, 6px);
          background: var(--themeColor);
          color: var(--_button-brand-primary-on-background, #FFF);
          /* web/cn/body/body-02 */
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: 0.5px;
          opacity:0.8;

          &:hover{
            border-radius: var(--radius-3, 6px);
            background: var(--themeColor);
            opacity:1;
          }

          &:active{
            border-radius: var(--radius-3, 6px);
            background: var(--el-color-primary);
          }

        }
        .register-tip{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--spacing-4, 8px);

          align-self: stretch;
          color: var(--text-on-surface-primary, #181B22);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          letter-spacing: 0.036px;
          :deep(.el-button--text) {
            padding: 2px 0;
            color: var(--themeColor);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            letter-spacing: 0.036px;
          }
        }
      }
    }
    .login-footer{
      color: var(--text-on-surface-tertiary, #707786);
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      letter-spacing: 0.036px;
      :deep(.el-button--text) {
        padding: 0;
        color: var(--themeColor);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        letter-spacing: 0.036px;
      }
    }
    .privacy-wrap{
      padding-top: 8px;
      display: flex;
      align-items: flex-start;
      .svg{
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .privacy{
        font-family: ABeeZee;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 3px;
        line-height: 24px;
        span{
          color: #034756;
        }
      }
    }
  }
</style>