<!--
 * @Description: 深圳水务登录页
 * @Author: luocheng
 * @Date: 2021-10-25 10:26:07
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-01-04 17:06:43
-->
<template>
  <div
    class="login"
    v-loading="loading"
    :style="{
      'background-image': 'url(' + loginBg + ')',
    }"
  >
    <div
      class='type-btn'
    >
      <el-button  @click="changeType()">
        {{ isRegister ? "立即登录" : "立即注册" }}
      </el-button>
    </div>
    <div v-if="!isRegister" class='login-div' >
      <div class="header-main" :style="{ 'background-color': bgcolor }">
        <div class="header-left-bottom">
          <div class="header-select">
            <div class='select-img' >
              <img
                v-if="loginType == '1'"
                src="https://static.bimcc.com/openim/yezhu9192D373663BDAEBAE8279E52FB0002F.png"
              />
              <img
                v-if="loginType == '3'"
                src="https://static.bimcc.com/openim/sheji05050E3D836A1AB0BF95EF2080AA9570.png"
              />
              <img
                v-if="loginType == '5'"
                src="https://static.bimcc.com/openim/shigongA16E1DF9754F820E4B2F9C2922E05C48.png"
              />
              <img
                v-if="loginType == '2'"
                src="https://static.bimcc.com/openim/jianli5BED6D0D5FD526ED0C0481CD08C9E48B.png"
              />
              <img
                v-if="loginType == '4'"
                src="https://static.bimcc.com/openim/kancha31C5C2CAB3212044931B58D14DB28EED.png"
              />
            </div>
            <div class="header-top">
              <el-select
                v-model="loginType"
                style="width: 70%"
                @change="changebgType"
              >
                <el-option
                  v-for="item in loginTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class='change-type'>
            <span :style="logintitle === 1  ? 'color:#fff;':'color:rgba(255, 255, 255, 0.6);'"
              @click="chagneLoginType(1)"
              >密码登录</span>
            <span :style="logintitle === 2 ? 'color:#fff;' : 'color:rgba(255, 255, 255, 0.6);'"
              @click="chagneLoginType(2)"
              >短信登录</span>
          </div>
          <el-form ref="loginForm" :model="loginForm" :rules="rules" class="login-form">
            <template v-if="logintitle === 1">
              <el-form-item prop="name" style="margin-top: 40px">
                <el-input
                  v-model="loginForm.name"
                  placeholder="用户名"
                  clearable
                  ><i slot="prefix" class="el-input__icon el-icon-s-custom"></i
                ></el-input>
              </el-form-item>
              <el-form-item prop="password" style="margin-top: 40px">
                <el-input
                  v-model="loginForm.password"
                  type="password"
                  placeholder="密码"
                  clearable
                  show-password
                  @enter="onLogin"
                  ><i slot="prefix" class="el-input__icon el-icon-lock"></i
                ></el-input>
              </el-form-item>
              <div
                v-if="showIdentifyCode"
                style="cursor: pointer; display: flex"
              >
                <el-input
                  v-model="inputCode"
                  placeholder="请输入验证码"
                  clearable
                  ><i
                    slot="prefix"
                    style="font-size: 20px; margin-left: 3px"
                    class="el-input__icon iconfont iconzhiliangguanli"
                  ></i
                ></el-input>
                <Identify @click="refreshCode" :identifyCode="identifyCode" />
              </div>
            </template>
            <template v-else>
              <el-form-item prop="phone" style="margin-top: 40px">
                <div class="code-item">
                  <el-input
                    autocomplete="new-password"
                    v-model="loginForm.phone"
                    placeholder="请输入手机号"
                    clearable
                    prefix-icon="el-icon-user-solid"
                  ></el-input>
                  <a
                    href="javascript::"
                    class="get-code"
                    @click="onGetCode"
                    :class="{
                      disabled: +codeStatus === 1 || +codeStatus === 3,
                    }"
                  >
                    <!--  验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时) -->
                    <template v-if="+codeStatus === 0">获取验证码</template>
                    <template v-else-if="+codeStatus === 1">获取中...</template>
                    <template v-else-if="+codeStatus === 2">重新获取</template>
                    <template v-else-if="+codeStatus === 3"
                      >{{ countDown }}秒后可重发</template
                    >
                  </a>
                </div>
              </el-form-item>
              <el-form-item prop="code" style="margin-top: 40px">
                <el-input
                  autocomplete="new-password"
                  v-model="loginForm.code"
                  placeholder="请输入验证码"
                  clearable
                  @enter="onLogin"
                >
                  <span slot="prefix" class="prefix">
                    <i class="iconfont iconsuoding"></i>
                  </span>
                </el-input>
              </el-form-item>
            </template>
            <div class="repwd" @click='onRePwd'> 忘记密码</div>
            <el-form-item>
              <el-button type="primary" @click="onLogin" class='login-btn'>登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <transition name="jl" mode="out-in">
        <div
          v-show="loginbgType == '1'"
          class="header-main-bg"
          :style="{
            'background-image': `url(https://static.bimcc.com/openim/bgyezhu87F411AB5595C9CD44F141C0B588345C.png)`,
          }"
        >
          <span
            @click="changebgType1('5')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 61%;
              left: 3%;
            "
          ></span>
          <span
            @click="changebgType1('3')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              bottom: 0;
              left: 56%;
            "
          ></span>
          <span
            @click="changebgType1('2')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 32%;
              right: 0;
            "
          ></span>
          <span
            @click="changebgType1('4')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 0;
              left: 47%;
            "
          ></span>
        </div>
      </transition>
      <transition name="jl" mode="out-in">
        <div
          v-show="loginbgType == '3'"
          class="header-main-bg"
          :style="{
            'background-image': `url(https://static.bimcc.com/openim/bgsheji9E1D36DD8E97CE4AEF484EE8827267FC.png)`,
          }"
          style="height: 629px; top: -5%"
        >
          <span
            @click="changebgType1('4')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 74%;
              left: 4%;
            "
          ></span>
          <span
            @click="changebgType1('1')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              bottom: 0.5%;
              left: 67%;
            "
          ></span>
          <span
            @click="changebgType1('5')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 31%;
              right: 0;
            "
          ></span>
          <span
            @click="changebgType1('2')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 29.5%;
              left: 0;
            "
          ></span>
        </div>
      </transition>
      <transition name="jl" mode="out-in">
        <div
          v-show="loginbgType == '5'"
          class="header-main-bg"
          :style="{
            'background-image': `url(https://static.bimcc.com/openim/bgshigong2D86271ACD7E5A2B171F7ACE37C1499D.png)`,
          }"
        >
          <span
            @click="changebgType1('3')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 65.5%;
              left: 3%;
            "
          ></span>
          <span
            @click="changebgType1('2')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              bottom: 3%;
              left: 55%;
            "
          ></span>
          <span
            @click="changebgType1('4')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 34%;
              right: 0;
            "
          ></span>
          <span
            @click="changebgType1('1')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 0;
              left: 44%;
            "
          ></span>
        </div>
      </transition>
      <transition name="jl" mode="out-in">
        <div
          v-show="loginbgType == '2'"
          class="header-main-bg"
          :style="{
            'background-image': `url(https://static.bimcc.com/openim/bgjianli8C8BE2FF8ED716DE359406BC23CA29C2.png)`,
          }"
          style="height: 625px"
        >
          <span
            @click="changebgType1('5')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 55%;
              left: 0%;
            "
          ></span>
          <span
            @click="changebgType1('3')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              bottom: 0%;
              left: 52%;
            "
          ></span>
          <span
            @click="changebgType1('4')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 0;
              right: 30%;
            "
          ></span>
          <span
            @click="changebgType1('1')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 10%;
              left: 10%;
            "
          ></span>
        </div>
      </transition>
      <transition name="jl" mode="out-in">
        <div
          v-show="loginbgType == '4'"
          class="header-main-bg"
          :style="{
            'background-image': `url(https://static.bimcc.com/openim/bgkanchaE94F66197015E85C969ED8FA6C9F5588.png)`,
          }"
        >
          <span
            @click="changebgType1('3')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 56.5%;
              left: 0%;
            "
          ></span>
          <span
            @click="changebgType1('2')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              bottom: 3%;
              left: 44%;
            "
          ></span>
          <span
            @click="changebgType1('1')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 0;
              right: 27%;
            "
          ></span>
          <span
            @click="changebgType1('5')"
            style="
              width: 80px;
              height: 80px;
              position: absolute;
              background: rgb(255, 255, 255, 0);
              top: 10%;
              left: 9%;
            "
          ></span>
        </div>
      </transition>
    </div>
    <Register v-else :rePassWord='rePassWord'  @onReset='onReset'/>
    <a class="filing-number" href="https://beian.miit.gov.cn/">渝ICP备13004974号-15</a>


  </div>
</template>

<script>
import mixin from "@/custom-component/login/mixin";
import Identify from "./components/identify.vue";
import { dataInterface } from "@/apis/data/index";
import Register from "./Register";
export default {
  name: "login",
  mixins: [mixin],
  components: {
    Register,
    Identify,
  },
  data() {
    const phoneReg =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    /* eslint-disable */
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号码!"));
      }
      if (!phoneReg.test(value)) {
        return callback(new Error("请输入正确格式的手机号码!"));
      }
      callback();
    };
    return {
      loginForm: {
        name: "",
        password: "",
        phone: "", // 手机号
        code: "", // 验证码
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      phoneReg,
      loginType: "1", // 1:建管中心  2： 全资/监理单位 3： 勘察设计单位 4：BIM咨询单位 5：施工总承包部  方便给第二屏传值  用num
      loginTypeOption: [
        {
          value: "1",
          label: "建设单位",
        },
        {
          value: "3",
          label: "勘察设计单位",
        },
        {
          value: "5",
          label: "施工总承包部",
        },
        {
          value: "2",
          label: "全咨/监理单位",
        },
        {
          value: "4",
          label: "BIM咨询单位",
        },
      ],
      logintitle: 1,
      // 验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时)
      codeStatus: 0,
      // 倒计时
      countDown: 0,
      // 结束时间戳
      endTime: 0,
      // 计时器对象
      interval: null,
      show: true,
      loginbgType: "1",
      loginbgType1: "",
      bgcolor: "rgba(255, 170, 201, 0.2)",
      loading: false,
      isRegister: false,
      defaultLogo:
        "https://static.bimcc.com/openim/1645435478_zMtB87ANzM6C75C0E54D74A410B54AA77A0ECBBE1C.jpg",
      // 图片验证码
      identifyCode: "",
      // 验证码规则
      identifyCodes: "3456789ABCDEFGHGKMNPQRSTUVWXY",
      inputCode: "",
      showIdentifyCode: false,
      rePassWord:false,
    };
  },
  computed: {
    loginBg() {
      return this.systemConfig?.login_logo || this.defaultLogo;
    },
  },
  watch: {
    loginType: function (val) {
      if (val == "1") {
        this.bgcolor = "rgba(255, 170, 201, 0.2)";
      } else if (val == "3") {
        this.bgcolor = "rgba(126, 227, 251, 0.2)";
      } else if (val == "5") {
        this.bgcolor = "rgba(0, 0, 0, 0.2)";
      } else if (val == "2") {
        this.bgcolor = "rgba(255, 255, 255, 0.2)";
      } else if (val == "4") {
        this.bgcolor = "rgba(21, 105, 171, 0.2)";
      }
    },
  },
  created() {
    document.onkeydown = (e) => {
      let key = window.event.keyCode;
      if (key == 13) {
        this.onLogin();
      }
    };
  },
  methods: {
    changeType(){
      this.isRegister = !this.isRegister;
      this.rePassWord = false;
    },
    onRePwd(){
      this.rePassWord = true;
      this.isRegister = true;
    },
    onReset(){
      this.rePassWord = false;
      this.isRegister = false;
    },
    chagneLoginType(type) {
      this.logintitle = type;
      // this.loginForm = {
      //   name: '',
      //   password: '',
      //   phone: '', // 手机号
      //   code: '', // 验证码
      // }
    },
    changebgType1(type) {
      this.loginType = type;
      this.loginbgType = "";
      setTimeout(() => {
        this.loginbgType = type;
      }, 150);
    },
    changebgType(e) {
      this.loginbgType = "";
      setTimeout(() => {
        this.loginbgType = e;
      }, 150);
    },
    onGetCode() {
      if (this.codeStatus === 1) {
        this.$message.warning("验证码获取中...");
        return;
      }
      if (this.codeStatus === 3) {
        this.$message.warning("请勿重复获取验证码!");
        return;
      }
      if (!this.loginForm.phone || !this.phoneReg.test(this.loginForm.phone)) {
        this.$message.error("请输入正确格式的手机号码！");
        return false;
      }
      this.codeStatus = 1;
      // console.log('获取');
      dataInterface({
        __method_name__: "globalFunctionCall",
        typeName: "LoginFuncClass",
        type: "behavior",
        funcName: "SendLoginSms",
        payload: {
          phone: this.loginForm.phone,
        },
      })
        .then((res) => {
          if (res && res.status === 200) {
            this.$message.success(res.data.data.msg);
            this.codeStatus = 3;
            this.endTime = new Date().getTime() + 60000 * 5;
            this.setCountDown();
            sessionStorage.setItem("codeTime", this.endTime);
          }
        })
        .catch((err) => {
          this.$message.error("验证码获取失败！");
          this.codeStatus = 0;
        });
    },
    /**
     * @desc: 倒计时
     */
    setCountDown() {
      const now = new Date().getTime();
      if (this.endTime && now > this.endTime) {
        this.codeStatus = 2;
        return;
      }
      this.countDown = Math.floor((this.endTime - now) / 1000);
      this.interval = setInterval(() => {
        if (this.countDown <= 0) {
          this.codeStatus = 2;
          clearInterval(this.interval);
          sessionStorage.removeItem("codeTime");
          return;
        }
        this.countDown--;
      }, 1000);
    },
    /**
     * @desc: 登录
     */
    onLogin() {
      let param;
      if (this.logintitle == 1) {
        param = {
          name: this.loginForm.name,
          password: this.loginForm.password,
        };
      } else if (this.logintitle == 2) {
        param = {
          code: this.loginForm.code,
          phone: this.loginForm.phone,
          loginType: "phone",
        };
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // this.$loading()
          if (this.showIdentifyCode && this.identifyCode !== this.inputCode) {
            this.$message.error("验证码错误！");
            this.refreshCode();
            return;
          }
          this.loading = true;
          try {
            this.doLogin({
              ...param,
            });
            this.loading = false;
            if (!this.showIdentifyCode && +this.errorTimes > 4) {
              this.showIdentifyCode = true;
              this.refreshCode();
            }
            localStorage.setItem("userType", this.loginType);
          } catch (err) {
            this.loading = false;
            this.$message.error("登录失败，请联系管理员！");
          }
        }
      });
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[
            Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
          ];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100vh;
  width: 100vw;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  // background: transparent;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  background-image: url("https://static.bimcc.com/openim/1645435478_zMtB87ANzM6C75C0E54D74A410B54AA77A0ECBBE1C.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: slideleft 20000s infinite linear;
  -webkit-animation: slideleft 20000s infinite linear;
  background-attachment: fixed;
  height: 100%;
  .system-name {
    height: 350px;
    color: #fff;
    position: relative;
    p {
      position: absolute;
      top: 150px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 45px;
      letter-spacing: 8px;
    }
  }
  .header-main-bg {
    z-index: 1;
    width: 643px;
    height: 612px;
    position: absolute;
    padding: 0 3em;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
    border-radius: 24px;
    box-sizing: border-box;
    // top: 23%;
    // left: 59%;
  }
  .header-main {
    z-index: 2;
    width: 400px;
    position: absolute;
    z-index: 999;
    padding: 0 3em;
    backdrop-filter: blur(20px);
    border-radius: 24px;
    box-sizing: border-box;
    // top: 50%;
    top: 13%;
    left: 21%;
    border: 0.5px solid rgba(223, 223, 223, 0.6);
    // border-image-source: linear-gradient(136.49deg, #DFDFDF 2.48%, #A0A0A0 98.05%);
    // left: 65%;
    .login-logo {
      display: block;
      width: 90px;
      margin: 0 auto;
      padding-bottom: 40px;
      object-fit: cover;
    }
    .header-left-bottom {
      .header-select{
        padding-left: 21%;
        width: 100%;
        display: flex;
        margin-top: 34px;
        .select-img{
          width: 30px;
          height: 30px;
          margin-top: 7px;
          img{
            widht:100%;
            height:100%
          }
        }
      }
      .change-type{
        display: flex;
        font-weight: 400;
        font-size: 20px;
        justify-content: space-around;
        margin-top: 34px;
      }
      .login-form{
        .login-btn{
          border-radius: 4px;
          background: #1e9eff;
          margin-top: 30px;
        }
        .repwd{
          text-align: right;
          color: #fff;
        }
      }
      h3 {
        font-size: 24px;
        color: rgb(45, 140, 240);
        text-align: center;
        margin-bottom: 2em;
      }
      :deep(.el-input__inner) {
        height: 40px;
        line-height: 40px;
      }
      :deep(.el-form-item--small.el-form-item) {
        margin-bottom: 25px !important;
      }
      .el-button {
        display: block;
        width: 100%;
        background: #007fe0;
        border: none;
        height: 34px;
        font-size: 14px;
        letter-spacing: 2px;
        &:hover {
          background: rgba(31, 104, 216, 0.8);
        }
      }
    }
  }
  /*-- copyright --*/
  .copyright {
    padding: 2em 0;
    text-align: center;
  }
  .copyright p {
    font-size: 15px;
    letter-spacing: 1px;
    color: #ccc;
    line-height: 1.8em;
  }
  .copyright p a {
    color: #fff;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
  }
}

@media (max-width: 667px) {
  h1 {
    font-size: 40px;
    letter-spacing: 3px;
  }
}

@media (max-width: 415px) {
  h1 {
    font-size: 30px;
    letter-spacing: 3px;
  }
  .social {
    margin: 1em 0 0;
  }
  .copyright {
    padding: 2em 1em;
  }
}
:deep(.el-form) {
  .el-input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 99999s;
      -webkit-transition: color 99999s ease-out,
        background-color 99999s ease-out;
    }
  }
}
</style>
<style lang="less" scoped>
@inputHeight: 40px;
@baseFont: 14px;
@baseColor: rgb(105, 138, 179);
@baseBg: rgb(236, 242, 252);
.login {
  position: relative;
  .type-btn{
    position: absolute; 
    right: 4%;
    top: 4%; 
    width: 90px;
    height: 32px;
    :deep(.el-button){
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.64);
      width: 100%;
      height: 100%;
      background-color: transparent;
      color: white;
    }
  }
  .login-div{
    width: 650px;
    height: 620px;
    position: absolute;
    top: 12%;
    left: 60%;
  }
  .header-top {
    :deep(.el-input){
      .el-input__inner {
        padding: 0 11px !important;
        color: #fff;
      }
    }
  }
 :deep(.el-input){
    .el-input__prefix {
      left: 0px;
      top: 2px;
    }
    .el-input__prefix {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-input__inner {
      background: transparent !important;
      border: none !important;
      color: #fff !important;
      font-size: 20px;
      padding: 0 27px;
      .el-input__icon {
        font-size: 20px;
      }
    }
  }
  // 备案号
  .filing-number {
    position: absolute;
    left: 0;
    bottom: 18px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: hsla(0,0%,100%,.64);
    letter-spacing: 1px;
    font-weight: 600;
  }
}

.code-item {
  display: flex;
  .el-input {
    flex: 1;
  }
  .get-code {
    height: @inputHeight;
    line-height: calc(@inputHeight - 20px);
    width: auto;
    min-width: 90px;
    background: @baseBg;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px 10px;
    color: @baseColor;
    font-size: @baseFont;
    text-align: center;
    &.disabled {
      background: #dcdfe6;
      color: #fff;
      cursor: not-allowed;
    }
  }
}
</style>

<style>
@keyframes fadenum {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0) rotate(0deg);
  }
}

@keyframes startfadenum {
  0% {
    transform: scale(0, 0) rotate(5deg);
  }
  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

.jl-enter-active {
  animation: startfadenum 0.3s ease-in;
  /* animation: startRotate 0.3s ease-in; */
}
.jl-leave-active {
  animation: fadenum 0.3s ease-in-out;
  /* animation: rotate 0.15s ease-in-out; */
}
</style>
