<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-03-21 09:13:40
 * @LastEditors: zqy
 * @LastEditTime: 2024-07-04 16:12:56
 * @FilePath: \dataview-viewer-test\src\views\login\jida\index.vue
 * @Description: 吉达登录
-->
<template>
  <div class="login-container">
    <section class="login-background-wrap">
    </section>
    <section class="login-main-wrap">
      <!-- 登录表单部分 -->
      <component :is="formMode" @changeMode="doChangeMode" @onShowPrivacy="onShowPrivacy"/>
    </section>
    <!-- 用户协议弹窗 -->
    <el-dialog
      :title="$t('login.privacyTitle')"
      custom-class="my-dialog"
      :visible.sync="showPrivacy"
      :before-close="onClose"
      center
      @closed="onClose"
    >
      <Privacy></Privacy>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showPrivacy = false">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import LoginForm from './components/LoginForm.vue';
import RegisterForm from './components/RegisterForm.vue';
import ForgetPassword from './components/ForgetPassword.vue';
import Privacy from '@/views/login/jida/components/Privacy.vue'

export default {
  components: {
    LoginForm,
    RegisterForm,
    ForgetPassword,
    Privacy
  },
  data() {
    return {
      formMode: 'LoginForm', // 表单模式 RegisterForm ForgetPassword
      showPrivacy: false
    }
  },
  methods: {
    onClose() {
      this.showPrivacy = false;
    },
    onShowPrivacy() {
      this.showPrivacy = !this.showPrivacy;
    },
    /**
     * @description: 改变模式
     * @param {*} mode
     * @return {*}
     */    
    doChangeMode(mode) {
      const modes = ['LoginForm', 'RegisterForm', 'ForgetPassword'];
      if(!modes.includes(mode)) {
        console.log(`mode ${mode} 不被支持！`)
        return;
      }
      this.formMode = mode;
    }
  }
}
</script>
<style lang="less" scoped>
  .login-container{
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url(https://al.jcdcinfrastructure.com/login_bg.png);
    background-size: cover;
    .login-background-wrap{
      flex-grow: 1;
      height: 100%;
      // 本来是图片 现在改的没有用了
    }
    .login-main-wrap{
      flex-shrink: 0;
      width: 456px;
      height: 100%;
      padding: 42px 48px 32px 48px;
      background: rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(10px);
      box-sizing: border-box;
    }
  }
  :deep(.my-dialog){
    height: 78%;
    overflow: hidden;
  }
  :deep(.el-dialog__body) {
    height: calc(100% - 100px);
    overflow: auto;
  }
</style>