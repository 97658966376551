var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"privacy-wrap"},[(!_vm.isEn)?_c('article',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):_vm._e(),(_vm.isEn)?_c('article',[_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center"},[_c('h5',[_vm._v("生效日期：")]),_c('p',{staticClass:"content-p"},[_vm._v("2024年3月15日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("一、引言")]),_c('p',{staticClass:"content-p indent block"},[_vm._v(" 中国港湾工程阿拉伯有限公司（以下简称“我们”或“公司”）致力于保护用户个人信息的安全与隐私，严格遵守沙特阿拉伯王国（以下简称“沙特”）的《个人数据保护法》（PDPL）及相关法律法规。本隐私声明详细阐述了我们在运营项目管理系统时，如何收集、使用、存储、共享和保护您的个人信息。请您在使用本系统前，仔细阅读并理解本隐私声明的所有条款。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("二、个人信息收集")]),_c('p',{staticClass:"content-p block"},[_c('span',{staticClass:"title"},[_vm._v("注册信息：")]),_vm._v(" 为了使用我们的项目管理系统，您可能需要提供姓名、电子邮件地址、手机号码、公司名称等基本信息进行注册。这些信息将用于身份验证、账户管理以及向您提供必要的服务。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("项目数据：")]),_vm._v(" 在项目管理过程中，您可能需要上传或输入项目相关的数据，如项目计划、进度报告、团队成员信息等。这些数据将仅用于支持项目管理和协作，确保项目的顺利进行。 ")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("使用日志：")]),_vm._v(" 为了保障系统安全稳定运行，我们可能会收集您的使用日志、IP地址等信息。这些信息将用于监控系统的使用情况、检测潜在的安全威胁和进行故障排查。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("三、个人信息使用")]),_c('p',{staticClass:"content-p block"},[_c('span',{staticClass:"title"},[_vm._v("服务提供：")]),_vm._v(" 我们将使用您提供的个人信息来验证您的身份、管理您的账户、提供项目管理服务，并响应您的请求或查询。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("数据分析：")]),_vm._v(" 我们可能会对您的使用行为数据进行分析，以优化系统性能、改进用户体验，并为用户提供更加个性化的服务。但此过程将遵循匿名化原则，确保不会泄露您的个人身份。 ")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("合规要求：")]),_vm._v(" 在遵守法律法规要求或响应政府、司法机构等合法请求的情况下，我们可能会披露您的个人信息。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("四、个人信息存储与保护")]),_c('p',{staticClass:"content-p block"},[_c('span',{staticClass:"title"},[_vm._v("安全存储：")]),_vm._v(" 我们将采取合理的技术和管理措施，确保您的个人信息在存储过程中的安全性，防止未经授权的访问、使用、泄露或破坏。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("数据加密：")]),_vm._v(" 对于敏感信息，我们将采用加密技术进行存储和传输，以保障数据安全。 ")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("访问控制：")]),_vm._v(" 我们限制访问个人信息的员工范围，并要求他们遵守严格的保密义务。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding-top":"5px"}},[_c('span',{staticClass:"title"},[_vm._v("数据备份与恢复：")]),_vm._v(" 我们定期对用户个人信息进行备份，并制定应急预案，以确保在数据丢失或损坏时能够迅速恢复。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("五、数据处理的合法性基础")]),_c('p',{staticClass:"content-p block",staticStyle:{"padding-bottom":"5px"}},[_vm._v("我们处理您的个人数据基于以下合法性基础：")]),_c('p',{staticClass:"content-p"},[_vm._v("1.您的明确同意；")]),_c('p',{staticClass:"content-p"},[_vm._v("2.处理符合您的实际利益，且与您的沟通不可能或困难；")]),_c('p',{staticClass:"content-p"},[_vm._v("3.处理是根据其他法律或履行您作为一方的先前协议；")]),_c('p',{staticClass:"content-p"},[_vm._v("4.如果我们是公共机构，处理是为了安全目的或满足司法要求；")]),_c('p',{staticClass:"content-p"},[_vm._v("5.在不违反法律规定、不损害您的权利和利益且不涉及处理敏感个人数据的前提下，处理是为了我们的合法利益（如保障系统安全、提升服务质量）。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("六、数据处理的合法性基础")]),_c('p',{staticClass:"content-p block indent"},[_vm._v("如果需要将您的个人数据跨境传输至第三国/地区，我们将确保接收国具备“充分个人数据保护水平”，或采取适当的措施（如签订标准合同条款、符合PDPL及其条例规定的认证等）来保护您的个人数据。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("七、您的权利")]),_c('p',{staticClass:"content-p block",staticStyle:{"padding-bottom":"5px"}},[_vm._v("根据PDPL，您享有以下权利：")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("知情权：")]),_vm._v(" 您有权了解我们收集、使用您的个人信息的具体情况。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("访问权：")]),_vm._v(" 您有权访问并获取您的个人信息副本。 ")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("更正权：")]),_vm._v(" 您有权要求我们更正不准确的个人信息。 ")]),_c('p',{staticClass:"content-p",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("删除权：")]),_vm._v(" 在符合法律规定的情况下，您有权要求我们删除您的个人信息。 ")]),_c('p',{staticClass:"content-p"},[_c('span',{staticClass:"title"},[_vm._v("反对权：")]),_vm._v(" 在特定情况下，您有权反对我们处理您的个人信息。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("八、联系方式")]),_c('p',{staticClass:"content-p indent block"},[_vm._v(" 如果您对本隐私声明有任何疑问或需要行使上述权利，请通过以下方式联系我们： ")]),_c('p',{staticClass:"content-p"},[_vm._v("邮箱：info@cccc4-ksa.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("九、隐私声明的更新与修改")]),_c('p',{staticClass:"content-p indent block"},[_vm._v(" 我们有权随时更新和修改本隐私声明。任何修改将在本网站上公布，并自公布之日起生效。我们建议您定期查看本隐私声明，以了解最新的隐私政策。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("十、法律适用与争议解决")]),_c('p',{staticClass:"content-p indent block"},[_vm._v(" 本隐私声明的解释、效力及争议解决均适用沙特法律。因本隐私声明引起的或与本隐私声明有关的任何争议，双方应首先通过友好协商解决；协商不成时，任何一方均有权向沙特有管辖权的法院提起诉讼。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finally block"},[_c('p',[_vm._v("中国港湾工程阿拉伯有限公司")]),_c('p',[_vm._v("2024年3月15日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-center"},[_c('h5',[_vm._v("Effective Date: March 15, 2024")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("I. Introduction")]),_c('p',{staticClass:"content-p-en indent block"},[_vm._v(" China Harbour Engineering Arabia Co.Ltd (hereinafter referred to as \"we\", \"us\", or the \"Company\") is committed to safeguarding the security and privacy of users' personal information, strictly adhering to the Personal Data Protection Law (PDPL) of the Kingdom of Saudi Arabia (hereinafter referred to as \"Saudi Arabia\") and relevant laws and regulations. This Privacy Statement elaborates on how we collect, use, store, share, and protect your personal information while operating our project management system. Please read and understand all the terms of this Privacy Statement carefully before using the system. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("II. Collection of Personal Information")]),_c('p',{staticClass:"content-p-en block"},[_c('span',{staticClass:"title"},[_vm._v("Registration Information:")]),_vm._v(" To use our project management system, you may be required to provide basic information such as your name, email address, mobile phone number, and company name for registration purposes. This information will be used for identity verification, account management, and to provide you with necessary services. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("Project Data:")]),_vm._v(" During the project management process, you may need to upload or enter project-related data, such as project plans, progress reports, team member information, etc. These data will be used solely to support project management and collaboration, ensuring the smooth progress of your projects. ")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Usage Logs: ")]),_vm._v(" To ensure the secure and stable operation of the system, we may collect information about your usage logs, IP addresses, and other relevant details. This information will be used to monitor system usage, detect potential security threats, and conduct troubleshooting activities. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("III. Use of Personal Information")]),_c('p',{staticClass:"content-p-en block"},[_c('span',{staticClass:"title"},[_vm._v("Service Provision: ")]),_vm._v(" We will use the personal information you provide to verify your identity, manage your account, provide project management services, and respond to your requests or inquiries. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("Data Analysis:")]),_vm._v(" We may analyze your usage behavior data to optimize system performance, improve user experience, and offer more personalized services to users. However, this process will adhere to the principle of anonymization to ensure that your personal identity is not disclosed. ")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Compliance Requirements:")]),_vm._v(" In compliance with legal and regulatory requirements or in response to legitimate requests from governments, judicial authorities, etc., we may disclose your personal information. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("IV. Storage and Protection of Personal Information")]),_c('p',{staticClass:"content-p-en block"},[_c('span',{staticClass:"title"},[_vm._v("Secure Storage:")]),_vm._v(" We will adopt reasonable technical and administrative measures to ensure the security of your personal information during storage, preventing unauthorized access, use, disclosure, or destruction. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("Data Encryption:")]),_vm._v(" For sensitive information, we will employ encryption technologies for storage and transmission to safeguard data security. ")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Access Control:")]),_vm._v(" We limit the scope of employees who have access to personal information and require them to comply with strict confidentiality obligations. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding-top":"5px"}},[_c('span',{staticClass:"title"},[_vm._v("Data Backup and Recovery:")]),_vm._v(" We regularly back up user personal information and develop emergency response plans to ensure prompt recovery in case of data loss or damage. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("V. Legal Basis for Data Processing")]),_c('p',{staticClass:"content-p-en block",staticStyle:{"padding-bottom":"5px"}},[_vm._v("Our processing of your personal data is based on the following legal grounds:")]),_c('p',{staticClass:"content-p-en"},[_vm._v("1. Your explicit consent;")]),_c('p',{staticClass:"content-p-en"},[_vm._v("2. Processing is necessary for your vital interests, and it is not possible or difficult to communiate with you;")]),_c('p',{staticClass:"content-p-en"},[_vm._v("3. Processing is required under other laws or to fulfill a previous agreement to which you are a party;")]),_c('p',{staticClass:"content-p-en"},[_vm._v("4. If we are a public authority, processing is necessary for security purposes or to comply with judicial requirements;")]),_c('p',{staticClass:"content-p-en"},[_vm._v("5. Processing is in our legitimate interests (such as ensuring system security and enhancing service quality), provided that it does not violate legal provisions, prejudice your rights and interests, and does not involve the processing of sensitive personal data.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("VI. Cross-border Transfer of Data")]),_c('p',{staticClass:"content-p-en block indent"},[_vm._v(" If there is a need to transfer your personal data across borders to a third country/region, we will ensure that the receiving country has an \"adequate level of protection for personal data\" or that appropriate measures (such as signing standard contractual clauses, obtaining certifications in compliance with PDPL and its regulations, etc.) are taken to protect your personal data. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("VII. Your Rights")]),_c('p',{staticClass:"content-p-en block",staticStyle:{"padding-bottom":"5px"}},[_vm._v("In accordance with PDPL, you have the following rights:")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Right to be Informed:")]),_vm._v(" You have the right to know the specific circumstances of how we collect and use your personal information. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("Right of Access: ")]),_vm._v(" You have the right to access and obtain a copy of your personal information. ")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Right to Rectification: ")]),_vm._v(" You have the right to request us to correct inaccurate personal information. ")]),_c('p',{staticClass:"content-p-en",staticStyle:{"padding":"5px 0"}},[_c('span',{staticClass:"title"},[_vm._v("Right to Erasure:")]),_vm._v(" Under legal circumstances, you have the right to request us to delete your personal information. ")]),_c('p',{staticClass:"content-p-en"},[_c('span',{staticClass:"title"},[_vm._v("Right to Object:")]),_vm._v(" In certain situations, you have the right to object to our processing of your personal information. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("VIII. Contact Information")]),_c('p',{staticClass:"content-p-en indent block"},[_vm._v(" If you have any questions about this Privacy Statement or need to exercise the rights mentioned above, please contact us via the following methods: ")]),_c('p',{staticClass:"content-p-en"},[_vm._v("Email: info@cccc4-ksa.com ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("IX. Updates and Modifications to the Privacy Statement")]),_c('p',{staticClass:"content-p-en indent block"},[_vm._v(" We reserve the right to update and modify this Privacy Statement at any time. Any modifications will be posted on this website and will take effect from the date of posting. We recommend that you review this Privacy Statement periodically to stay informed of our latest privacy policies. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('h5',[_vm._v("X. Governing Law and Dispute Resolution")]),_c('p',{staticClass:"content-p-en indent block"},[_vm._v(" The interpretation, validity, and dispute resolution of this Privacy Statement shall be governed by the laws of Saudi Arabia. Any disputes arising from or in connection with this Privacy Statement shall first be resolved through friendly consultation between the parties; if no agreement can be reached, any party has the right to file a lawsuit with a court having jurisdiction in Saudi Arabia. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-finally block"},[_c('p',[_vm._v("China Harbour Engineering Arabia Co.Ltd")]),_c('p',[_vm._v("Effective Date: March 15, 2024")])])
}]

export { render, staticRenderFns }