<!--
 * @Description: 登录页
 * @Author: luocheng
 * @Date: 2021-10-25 10:26:07
 * @LastEditors: wsp 542278473@qq.com
 * @LastEditTime: 2024-03-23 09:29:49
-->
<template>
  <div class="login-index">
    <template v-if="systemConfig && systemConfig.custom_login">
      <!-- 自定义登录页 -->
      <CustomLogin :pageUUID="systemConfig && systemConfig.login_uuid"></CustomLogin>
    </template>
    <template v-else>
      <!-- 葛洲坝 -->
      <GZBIndex v-if="projectEnvironment === 'gzb'"></GZBIndex>
      <!-- 南华 -->
      <NHLogin v-else-if="projectEnvironment === 'nh'"></NHLogin>
      <!-- 濮阳工学院 -->
      <PYGXYLogin v-else-if="projectEnvironment === 'pygxy'"></PYGXYLogin>
      <!-- 中设项目 -->
      <ZSSJZXLogin v-else-if="projectEnvironment === 'zssjzx'"></ZSSJZXLogin>
      <!-- 深圳水务 罗铁 -->
      <LTLogin v-else-if="projectEnvironment === 'ltsd'"></LTLogin>
      <!-- 重庆建工 -->
      <CQJGLogin v-else-if="projectEnvironment === 'cqjg'"></CQJGLogin>
      <!-- 吉达登录占位 -->
      <JIDALogin v-else-if="projectEnvironment === 'st'" />
      <!-- dev -->
      <DevLogin v-else></DevLogin>
    </template>
  </div>
</template>

<script>
import NHLogin from './nh/Index';
import GZBIndex from './gzb/Index';
import DevLogin from './dev/Index.vue';
import PYGXYLogin from './pygxy/Index';
import ZSSJZXLogin from './zssjzx/Index';
import LTLogin from './szsw/Index';
import CQJGLogin from './cqjg/Index';
import CustomLogin from './custom/Index';
import JIDALogin from './jida/index'
import { mapState } from 'vuex';

export default {
  name: 'Login',
  components: {
    NHLogin,
    GZBIndex,
    DevLogin,
    PYGXYLogin,
    ZSSJZXLogin,
    LTLogin,
    CustomLogin,
    CQJGLogin,
    JIDALogin
  },
  computed: {
    ...mapState(['projectEnvironment', 'systemConfig'])
  },
}
</script>

<style lang="less" scoped>
.login-index{
  height: 100%;
  width: 100%;
  min-width: 860px;
  min-height: 800px;
  background: #fff;
  overflow: hidden;
  position: relative;
}
</style>