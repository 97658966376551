<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-03-22 13:48:23
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 17:56:08
 * @FilePath: /dataview-viewer-test/src/views/login/jida/components/ProjectSelect.vue
 * @Description: 项目选择
-->
<template>
  <el-select
    :value="label"
    :placeholder="placeholder"
    ref="select"
  >
    <template v-slot:empty>
      <div class="register-project-select-tree-area">
        <el-tree
          :data="options"
          :props="defaultProps"
          @node-click="handleNodeClick"
          :expand-on-click-node="false"
          :default-expand-all="true"
          :check-strictly="true"
          node-key="id"
          :current-node-key="value"
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </template>
  </el-select>
</template>
<script>
import { dataInterface } from '@/apis/data';
export default {
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [],
      names: {},
      defaultProps: {
        children: 'child',
        label: 'name'
      }
    }
  },
  computed: {
    /**
     * @description: 回显label
     * @return {*}
     */    
    label() {
      return this.names[this.value] || ''
    }
  },
  created() {
    this.getProjects();
  },
  methods: {
    /**
     * @description: 获取项目
     * @return {*}
     */    
    getProjects() {
      const REQDATA = {
        type_value: 'projects'
      };
      dataInterface(REQDATA, 'api/graph/805').then((res) => {
        if(res.data.code === 200) {
          this.options = res.data.data;
          this.initNames(res.data.data);
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    /**
     * @description: 初始化项目名称
     * @param {*} data
     * @return {*}
     */    
    initNames(data) {
      if(Array.isArray(data)) {
        data.forEach(el => {
          const { id, name, child } = el;
          this.$set(this.names, id, name);
          if(Array.isArray(child)) {
            this.initNames(child)
          }
        })
      }
    },
    /**
     * @description: 节点点击
     * @param {*} node
     * @return {*}
     */    
    handleNodeClick(node) {
      const { id } = node;
      this.$emit('input', id);
      this.$emit('change', id);
      if(this.$refs.select) {
        this.$refs.select.blur();
      }
    }
  }
}
</script>
<style lang="less">
  // 长前缀谨防样式冲突
  .register-project-select-tree-area{
    max-height: 350px;

    padding: 8px 0;
    box-sizing: border-box;

    .el-tree{
      .el-tree-node__content{
        height: 34px;
      }
      .is-current{
        & > .el-tree-node__content{
          color: var(--themeColor);
        }
      }
    }
  }
</style>
